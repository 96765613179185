var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"assetStandardForm"},[_c('div',{staticClass:"assetStandardForm-content"},[_c('form-panel',{ref:"form",staticClass:"form",attrs:{"labelWidth":"0","hasHeader":false,"submitUrl":_vm.submitAssetStandard,"form":_vm.formList,"queryUrl":_vm.getAssetDetail,"submitBefore":_vm.submitBefore},on:{"update":_vm.update},scopedSlots:_vm._u([{key:"saveBeforeSlot",fn:function(){return [_c('v-button',{attrs:{"text":"取消","type":"plain"},on:{"click":function($event){return _vm.$router.back()}}})]},proxy:true}])},[_c('div',{staticClass:"title"},[_vm._v("基本信息")]),_c('el-form-item',{attrs:{"prop":"name","label":"资产名称","rules":[
            {
              required: true,
              message: '请输入资产名称',
              trigger: ['change', 'blur'],
            },
          ]}},[_c('v-input',{model:{value:(_vm.formList.name),callback:function ($$v) {_vm.$set(_vm.formList, "name", $$v)},expression:"formList.name"}})],1),_c('div',{staticClass:"title"},[_vm._v("定义属性")]),_c('div',{staticClass:"table"},[_c('v-table',{attrs:{"tableData":_vm.formList.fieldConfigs,"headers":[],"isOperateColumn":false},scopedSlots:_vm._u([{key:"customTableColumn",fn:function(){return [_c('el-table-column',{attrs:{"label":"字段","prop":"field"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('el-form-item',{attrs:{"prop":`fieldConfigs.${$index}.field`,"rules":[
                      {
                        required: true,
                        message: '请输入字段',
                        trigger: ['change', 'blur'],
                      },
                    ]}},[_c('v-input',{attrs:{"placeholder":"请输入字段"},model:{value:(row.field),callback:function ($$v) {_vm.$set(row, "field", $$v)},expression:"row.field"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"类型","prop":"type"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('el-form-item',{attrs:{"prop":`fieldConfigs.${$index}.type`}},[_c('v-cascader',{staticClass:"cascader",attrs:{"options":_vm.typeOptions,"props":_vm.cascaderProps},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"长度","prop":"size"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('el-form-item',{attrs:{"prop":`fieldConfigs.${$index}.size`,"rules":[
                      {
                        required: true,
                        message: '请输入长度',
                        trigger: ['change', 'blur'],
                      },
                    ]}},[_c('v-input',{attrs:{"placeholder":"请输入长度"},model:{value:(row.size),callback:function ($$v) {_vm.$set(row, "size", $$v)},expression:"row.size"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"描述","prop":"remark"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('el-form-item',{attrs:{"prop":`fieldConfigs.${$index}.remark`,"rules":[
                      {
                        required: true,
                        message: '请输入描述',
                        trigger: ['change', 'blur'],
                      },
                    ]}},[_c('v-input',{attrs:{"type":"textarea","rows":3},model:{value:(row.remark),callback:function ($$v) {_vm.$set(row, "remark", $$v)},expression:"row.remark"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('div',{staticClass:"section-circle"},[_c('div',{staticClass:"circle-button",on:{"click":function($event){return _vm.addTableData(row, $index)}}},[_c('i',{staticClass:"el-icon-circle-plus"})]),($index != 0)?_c('div',{staticClass:"circle-button",on:{"click":function($event){return _vm.delTableData(row, $index)}}},[_c('i',{staticClass:"el-icon-remove"})]):_vm._e()])]}}])})]},proxy:true}])})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }