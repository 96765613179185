<template>
  <div class="assetStandardForm">
    <div class="assetStandardForm-content">
      <form-panel
        class="form"
        ref="form"
        labelWidth="0"
        :hasHeader="false"
        :submitUrl="submitAssetStandard"
        :form="formList"
        :queryUrl="getAssetDetail"
        :submitBefore="submitBefore"
        @update="update"
      >
        <div class="title">基本信息</div>
        <el-form-item
          prop="name"
          label="资产名称"
          :rules="[
            {
              required: true,
              message: '请输入资产名称',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <v-input v-model="formList.name"></v-input>
        </el-form-item>
        <div class="title">定义属性</div>
        <div class="table">
          <v-table
            :tableData="formList.fieldConfigs"
            :headers="[]"
            :isOperateColumn="false"
          >
            <template #customTableColumn>
              <el-table-column label="字段" prop="field">
                <template slot-scope="{ row, $index }">
                  <el-form-item
                    :prop="`fieldConfigs.${$index}.field`"
                    :rules="[
                      {
                        required: true,
                        message: '请输入字段',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <v-input
                      placeholder="请输入字段"
                      v-model="row.field"
                    ></v-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="类型" prop="type">
                <template slot-scope="{ row, $index }">
                  <el-form-item :prop="`fieldConfigs.${$index}.type`">
                    <v-cascader
                      class="cascader"
                      v-model="row.type"
                      :options="typeOptions"
                      :props="cascaderProps"
                    ></v-cascader>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="长度" prop="size">
                <template slot-scope="{ row, $index }">
                  <el-form-item
                    :prop="`fieldConfigs.${$index}.size`"
                    :rules="[
                      {
                        required: true,
                        message: '请输入长度',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <v-input
                      placeholder="请输入长度"
                      v-model="row.size"
                    ></v-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="描述" prop="remark">
                <template slot-scope="{ row, $index }">
                  <el-form-item
                    :prop="`fieldConfigs.${$index}.remark`"
                    :rules="[
                      {
                        required: true,
                        message: '请输入描述',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <v-input
                      v-model="row.remark"
                      type="textarea"
                      :rows="3"
                    ></v-input>
                  </el-form-item> </template
              ></el-table-column>
              <el-table-column label="操作" width="150px">
                <template slot-scope="{ row, $index }">
                  <div class="section-circle">
                    <div
                      class="circle-button"
                      @click="addTableData(row, $index)"
                    >
                      <i class="el-icon-circle-plus"></i>
                    </div>
                    <div
                      class="circle-button"
                      v-if="$index != 0"
                      @click="delTableData(row, $index)"
                    >
                      <i class="el-icon-remove"></i>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </template>
          </v-table>
        </div>
        <template #saveBeforeSlot>
          <v-button text="取消" type="plain" @click="$router.back()"></v-button>
        </template>
      </form-panel>
    </div>
  </div>
</template>

<script>
import {
  getTypeListCode,
  submitAssetStandard,
  getAssetDetail,
  getStoreList,
} from "./api.js";
export default {
  name: "assetStandardForm",
  components: {},
  props: {},
  data() {
    return {
      submitAssetStandard,
      getAssetDetail,
      formList: {
        id: "",
        name: "",
        fieldConfigs: [
          {
            size: "", //长度
            field: "", //字段
            remark: "", //描述
            type: [], //类型
          },
        ],
      },
      typeOptions: [],
      typeOptionsIds: [],
      cascaderProps: {
        value: "dictKey",
        label: "dictValue",
      },
      filedTypeDictKey: [],
    };
  },
  created() {},

  async mounted() {
    await this.getTypeListCode();
    const { id } = this.$route.query;
    if (id != undefined) {
      this.$refs.form.getData({ id });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    // 格式数据
    formatFiledTypeData(data, id = [], dictKey = []) {
      data.forEach((v) => {
        if (v.children) {
          this.formatFiledTypeData(
            v.children,
            [...id, v.id],
            [...dictKey, v.dictKey]
          );
        } else {
          this.filedTypeDictKey.push(v);
        }

        v.allDictKey = [...dictKey, v.dictKey];
        v.allIds = [...id, v.id];
        console.log("v----->", v);
      });
      return data;
    },
    //回显
    update(data) {
      Object.keys(this.formList).forEach((key) => {
        this.formList[key] = data[key] || this.form[key];
      });
      this.formList.fieldConfigs.forEach((item) => {
        item.type ? (item.type = item.type.split(",")) : (item.type = []);
      });
      this.filedTypeDictKey.forEach((item) => {
        console.log("item----->", item);
        this.formList.fieldConfigs.forEach((arg) => {
          if (arg.type.includes(item.dictKey)) {
            arg.type = [...item.allDictKey];
          }
        });
      });
    },
    submitBefore() {
      const res = this.formList.fieldConfigs.map((item) => ({
        ...item,
        type: item?.type.length > 0 ? item.type[item.type.length - 1] : "",
      }));
      const params = {
        id: this.formList.id,
        name: this.formList.name,
        fieldConfigs: res,
      };
      return params;
    },
    //获取类型字段
    async getTypeListCode() {
      const params = {
        code: "db_type",
      };
      const res = await this.$axios.get(getTypeListCode, { params });
      if (res.code == 200) {
        this.typeOptions = this.formatFiledTypeData(res.data);
        // this.typeOptions = res.data;
      }
    },
    //添加数据
    addTableData(row, index) {
      this.formList.fieldConfigs.splice(index + 1, 0, {
        size: "", //长度
        field: "", //字段
        remark: "", //描述
        type: [], //类型
      });
    },
    //删除
    delTableData(row, index, gbRangeForm) {
      this.formList.fieldConfigs.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="less">
.assetStandardForm {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  .assetStandardForm-content {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 35px;
    box-sizing: border-box;
    ::v-deep .footer-button {
      display: flex;
      justify-content: center;
    }
    ::v-deep .el-form {
      display: flex;
      flex-direction: column;
      height: 100%;
      .cascader {
        width: 100%;
        .el-cascader {
          width: 100% !important;
        }
      }
    }
    .form-panel-container {
      padding-left: 50px;
      ::v-deep .el-form-item__label {
        width: auto !important;
      }
      .title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
        color: #000;
      }
      .table {
        flex: 1;
        width: 100%;
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
        .section-circle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 20px;
          .circle-button {
            font-size: 24px;
          }
        }
      }
    }
    .section-search {
      display: flex;
      justify-content: space-between;
      .search-l {
        display: flex;
        .search-input {
          margin-right: 20px;
        }
      }
    }
    .section {
      .title {
        font-weight: 600;
        font-size: 18px;
        color: #000;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
